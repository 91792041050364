import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/theme/typography.js\",\"import\":\"Public_Sans\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"fallback\":[\"Helvetica\",\"Arial\",\"sans-serif\"]}],\"variableName\":\"primaryFont\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/theme/typography.js\",\"import\":\"Barlow\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"fallback\":[\"Helvetica\",\"Arial\",\"sans-serif\"]}],\"variableName\":\"secondaryFont\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/src/auth/context/token/auth-consumer.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/auth/context/token/auth-context.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/auth/context/token/auth-provider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/animate/motion-lazy.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/settings/context/settings-context.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/settings/context/settings-provider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/settings/drawer/settings-drawer.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/snackbar/snackbar-provider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/global.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/locales/config-lang.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/locales/i18n.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/locales/localization-provider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/locales/use-locales.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/theme/index.js")